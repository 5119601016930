@import "generated/icons/index.css";
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,500&amp;subset=japanese");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  @apply text-black90 font-regular;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

* {
  @apply font-sans;
}

/* Spinner */

.spinner-wrapper {
  @apply w-full h-full flex items-center justify-center;
}

.break-all {
  word-break: break-all;
}

.keep-all {
  word-break: keep-all;
}

.spinner {
  @apply relative mx-auto text-center;
  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}

.dot1 {
  @apply absolute top-0 bg-primary55 rounded-full inline-block;
  height: 60%;
  width: 60%;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
  @apply absolute inset-auto bottom-0 bg-primary55 rounded-full inline-block;
  height: 60%;
  width: 60%;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

/* Skeleton */

.skeleton-small-table {
  @apply flex flex-col p-3 w-full;
}

.skeleton-small-table-first-line {
  @apply h-3 rounded-full bg-no-repeat;
  width: 80%;
  background-color: rgba(2, 8, 2, 0.02);
  background-image: linear-gradient(
    to right,
    rgba(2, 8, 2, 0) 0%,
    rgba(250, 250, 250, 0.8) 50%,
    rgba(2, 8, 2, 0) 100%
  );
  background-size: 60%;
  animation: shine-lines 2s infinite ease-out;
}

.skeleton-small-table-second-line {
  @apply h-3 mt-2 rounded-full bg-no-repeat;
  width: 60%;
  background-color: rgba(2, 8, 2, 0.02);
  background-image: linear-gradient(
    to right,
    rgba(2, 8, 2, 0) 0%,
    rgba(250, 250, 250, 0.8) 50%,
    rgba(2, 8, 2, 0) 100%
  );
  background-size: 60%;
  animation: shine-lines 2s infinite ease-out;
}

@keyframes shine-lines {
  0% {
    background-position: -260%;
  }

  100% {
    background-position: 260%;
  }
}

/* Text sizes */

.text-size-h1 {
  @apply text-h1 leading-h1;
}

.text-size-h2 {
  @apply text-h2 leading-h2;
}

.text-size-h3 {
  @apply text-h3 leading-h3;
}

.text-size-h4 {
  @apply text-h4 leading-h4;
}

.text-size-h5 {
  @apply text-h5 leading-h5;
}

.text-size-h6 {
  @apply text-h6 leading-h6;
}

.text-size-body {
  @apply text-body leading-body;
}

.text-size-small {
  @apply text-small leading-small;
}

.text-size-caption {
  @apply text-caption leading-caption;
}

h1 {
  @apply text-h1 leading-h1;
}

h2 {
  @apply text-h2 leading-h2;
}

h3 {
  @apply text-h3 leading-h3;
}

h4 {
  @apply text-h4 leading-h4;
}

h5 {
  @apply text-h5 leading-h5;
}

h6 {
  @apply text-h6 leading-h6;
}

/* Editor */

[contenteditable="true"] {
  height: 100%;
  width: 100%;
  @apply outline-none;
}

#markdown h1 {
  @apply text-size-h4 mb-4;
}

#markdown h2 {
  @apply text-size-small font-bold my-3;
}

#markdown p {
  @apply text-caption leading-caption mb-2;
}

/* Alert */
@keyframes alert {
  from {
    left: 100%;
    position: absolute;
  }
  to {
    left: calc(100% - 300px);
    position: relative;
  }
}

.alert {
  animation-name: alert;
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
}

@keyframes alert-disposed {
  from {
    left: calc(100% - 300px);
    position: relative;
  }
  to {
    left: 100%;
    position: absolute;
  }
}

.alert-disposed {
  animation-name: alert-disposed;
}

/* Gradient background */

.bg-gradient {
  background: linear-gradient(270deg, rgb(245, 241, 255) 0%, rgb(240, 250, 249) 100%);
}

.arrow-box {
  position: relative;
}

.arrow-box:after {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow-box:after {
  border-right-color: rgba(0, 183, 0, 0.1);
  border-width: 8px;
  margin-top: -8px;
}

/* .aspect2to1 {
  padding: 25% 0 25% 0;
  @import "generated/icons";
} */

.loader-circle-1 {
  animation-delay: 0.1s;
}
.loader-circle-2 {
  animation-delay: 0.2s;
}
.loader-circle-3 {
  animation-delay: 0.3s;
}

.hidden {
  display: none;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
