@font-face {
    font-family: "icons";
    src: url("../fonts/icons.ttf?133f6a58703ef3ac878dadef35e605f0") format("truetype"),
url("../fonts/icons.woff?133f6a58703ef3ac878dadef35e605f0") format("woff"),
url("../fonts/icons.woff2?133f6a58703ef3ac878dadef35e605f0") format("woff2");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left:before {
    content: "\f101";
}
.icon-building:before {
    content: "\f102";
}
.icon-calendar-plus:before {
    content: "\f103";
}
.icon-calendar:before {
    content: "\f104";
}
.icon-check-circle:before {
    content: "\f105";
}
.icon-checkbox-checked:before {
    content: "\f106";
}
.icon-checkbox:before {
    content: "\f107";
}
.icon-chevron-down:before {
    content: "\f108";
}
.icon-chevron-left:before {
    content: "\f109";
}
.icon-chevron-right:before {
    content: "\f10a";
}
.icon-chevron-up:before {
    content: "\f10b";
}
.icon-clipboard:before {
    content: "\f10c";
}
.icon-copy:before {
    content: "\f10d";
}
.icon-download:before {
    content: "\f10e";
}
.icon-editor-pen:before {
    content: "\f10f";
}
.icon-editor-text-regular:before {
    content: "\f110";
}
.icon-error-circle:before {
    content: "\f111";
}
.icon-error:before {
    content: "\f112";
}
.icon-group:before {
    content: "\f113";
}
.icon-image:before {
    content: "\f114";
}
.icon-info-circle:before {
    content: "\f115";
}
.icon-list-plus:before {
    content: "\f116";
}
.icon-log-out:before {
    content: "\f117";
}
.icon-magnet:before {
    content: "\f118";
}
.icon-message-detail:before {
    content: "\f119";
}
.icon-news:before {
    content: "\f11a";
}
.icon-plus-circle:before {
    content: "\f11b";
}
.icon-plus-square:before {
    content: "\f11c";
}
.icon-quote-left:before {
    content: "\f11d";
}
.icon-quote-right:before {
    content: "\f11e";
}
.icon-quote:before {
    content: "\f11f";
}
.icon-radio-circle-marked:before {
    content: "\f120";
}
.icon-radio-circle:before {
    content: "\f121";
}
.icon-shield:before {
    content: "\f122";
}
.icon-sitemap:before {
    content: "\f123";
}
.icon-sort-alt:before {
    content: "\f124";
}
.icon-time:before {
    content: "\f125";
}
.icon-toggle-left:before {
    content: "\f126";
}
.icon-toggle-right:before {
    content: "\f127";
}
.icon-trash:before {
    content: "\f128";
}
.icon-user-circle:before {
    content: "\f129";
}
.icon-user-detail:before {
    content: "\f12a";
}
.icon-user:before {
    content: "\f12b";
}
.icon-vertical-dots:before {
    content: "\f12c";
}
.icon-videos:before {
    content: "\f12d";
}
.icon-windows:before {
    content: "\f12e";
}
.icon-x-circle:before {
    content: "\f12f";
}
.icon-x-square:before {
    content: "\f130";
}
.icon-x:before {
    content: "\f131";
}
